import apis from 'browser/app/models/apis';
import React from 'react';
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props';

interface IMotiveRegisterProps extends IBaseProps {
  location: any
}

interface IMotiveRegisterState {
  isLoading: boolean
  hasError: boolean
  code: string | null
}

export class MotiveRegister extends React.Component<IMotiveRegisterProps, IMotiveRegisterState> {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      hasError: false,
      code: null,
    };
  }

  componentDidMount() {
    const { location } = this.props;
    const code = new URLSearchParams(location.search).get('code');

    if (code) {
      this.setState({ code, isLoading: true, hasError: false }, this.registerMotive);
    }
  }

  async registerMotive() {
    const { code } = this.state;

    if (!code) return;

    try {
      console.log('registering motive');
      await apis.registerMotive(code);
      console.log('registered motive');
      this.setState({ isLoading: false, hasError: false });
    } catch (error) {
      console.error('Error registering motive:', error);
      this.setState({ isLoading: false, hasError: true });
    }
  }

  render() {
    const { isLoading, hasError, code } = this.state;

    if (!code) {
      return (
        <div>
          <h1>No code passed!</h1>
        </div>
      );
    }

    if (isLoading) {
      return (
        <div>
          <h1>Registering motive...</h1>
        </div>
      );
    }

    if (hasError) {
      return (
        <div>
          <h1>Failed to register motive. Please try again.</h1>
        </div>
      );
    }

    return (
      <div>
        <h1>Motive registration complete!</h1>
      </div>
    );
  }
}

export default MotiveRegister;